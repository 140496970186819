// rich-text.scss overrides needed for Prince XML
@use 'direction';

// DIRECTION
@include direction.biDirectionalViewEncapsulationNone() using($start, $end) {
  .accredible-rich-text-wrapper {
    ul,
    ol {
      // Prince XML doesn't support the padding-inline-start property
      padding-#{$start}: 30px;
    }

    ol li::marker {
      padding-#{$end}: 8px;
    }

    ul li::marker {
      padding-#{$end}: 12px;
    }
  }
}
