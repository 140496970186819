@use 'sass:math';

// Rich Text Editor styles
@use 'rich-text';
@use 'rich-text-prince';

// Variables
// A historically important variable - see the main block-comment and variable definition for details
$certificate-desktop-fontsize-constant: 788;

// Define our variants
$sizes: a4, a8, us-letter;
$orientations: landscape, portrait;

@mixin generateVariant($width, $height) {
  width: $width;
  height: $height;
  // The font relates to the original editor size
  font-size: math.div($width, $certificate-desktop-fontsize-constant);
}

// Utility :: String Replacement
// https://github.com/hail2u/scss-functions/blob/master/string/_str-replace.scss
@function str-replace($string, $substr, $newsubstr, $all: 0) {
  $position-found: str-index($string, $substr);
  $processed: ();
  @while ($position-found and $position-found > 0) {
    $length-substr: str-length($substr);
    @if (1 !=$position-found) {
      $processed: append($processed, str-slice($string, 0, $position-found - 1));
    }
    $processed: append($processed, $newsubstr);
    $string: str-slice($string, $position-found + $length-substr);
    $position-found: 0;
    @if ($all > 0) {
      $position-found: str-index($string, $substr);
    }
  }
  $processed: append($processed, $string);
  $string: '';

  @each $s in $processed {
    $string: #{$string}#{$s};
  }

  @return $string;
}

// Utility - tidies valid classNames to work as valid @page name
@function tidy-classname($className) {
  @return str-replace($className, '-', '_');
}

// Remove body margins
body {
  margin: 0;
}

// Force single page
.credential-wrapper {
  position: absolute;
  // !important is needed in order for the properties below to not be converted to inset. The BE renderer princexml lib doesn't support the inset property.
  top: 0 !important;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

// Page basics (all pages inherit this)
@page {
  margin: 0;
  border: 0;
  padding: 0;
}

// Loop and generate our @page'd media
@each $size in $sizes {
  @each $orientation in $orientations {
    // An @page name cannot contain hyphens
    $sizeSafe: tidy-classname($size);
    $orientationSafe: tidy-classname(
      $orientation
    ); // Generate a named page, then "activate" it in the presence of the <certificate> with that class
    @page #{$sizeSafe}_#{$orientationSafe} {
      size: $size $orientation;
    }
    .credential-wrapper.#{$size}.#{$orientation} {
      page: #{$sizeSafe}_#{$orientationSafe};
    }
    // Repeat for the presence of a "print-quality" class
    @page #{$sizeSafe}_#{$orientationSafe}_print {
      size: $size $orientation;
      marks: crop cross;
      prince-bleed: 10mm;
      prince-trim: 10mm;
    }
    .credential-wrapper.print-quality.#{$size}.#{$orientation} {
      page: #{$sizeSafe}_#{$orientationSafe}_print;
    }
  }
}

// Helps debugging when testing locally
div.certificate {
  .certificate-inner {
    pointer-events: all !important;
  }
}

.credential-wrapper {
  // Modifier rtl
  &.rtl .certificate-inner {
    transform-origin: top right;
  }

  // Size and orientation variants
  &.a4.landscape {
    @include generateVariant(297mm, 210mm);
  }

  &.a4.portrait {
    @include generateVariant(210mm, 297mm);
  }

  &.a8.landscape {
    @include generateVariant(74mm, 52mm);
  }

  &.a8.portrait {
    @include generateVariant(52mm, 74mm);
  }

  &.us-letter.landscape {
    @include generateVariant(11in, 8.5in);
  }

  &.us-letter.portrait {
    @include generateVariant(8.5in, 11in);
  }
}

// Expiry notice styling
.certificate-expired-notice {
  position: absolute;
  z-index: 1; // Sit on top
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 272px;
  height: 50px;
  padding: 14px 21px;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
  border-top-left-radius: 6px;
  box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.35);
  // Using SVG so we can avoid loading in the icon font for this one instance
  $clockSize: 22px;

  > svg {
    width: $clockSize;
    height: $clockSize;
    margin-right: 5px;
    line-height: $clockSize;
    vertical-align: top;
    fill: #bf202d;
  }
}

// Override SVG sizing
svg {
  width: auto;
  height: auto;
}
